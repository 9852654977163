import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL } from '../actions/constant';
import { geterrors, clearerror } from './errorReducer'



export const counterSlice = createSlice({
    name: 'kyc',
    initialState: {
        list: null,
        loading: false,
        add: null,
        edit: null,
    },
    reducers: {
        setloading: (state, action) => {
            state.loading = action.payload
        },

        list: (state, action) => {
            state.list = action.payload.data
            state.loading = false

        },
        add: (state, action) => {
            state.add = action.payload
            state.loading = false

        },
        edit: (state, action) => {
            state.edit = action.payload
            state.loading = false

        },
    },
})

export const { setloading, list, add, edit } = counterSlice.actions

//API CALLS
export const listKyc = () => dispatch => {
    dispatch(setloading(true));
    dispatch(clearerror())
    axios.get(`${API_URL}/api/kyc/`)
        .then(res => {
            // dispatch(list(res.data));
            dispatch(list({ data: res.data }));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setloading(false));
            dispatch(geterrors(err.response.data))
        });
};

export const addKycData = (data) => dispatch => {
    dispatch(setloading(true));

    dispatch(clearerror())

    axios.post(API_URL + "/api/kyc/", data)
        .then(res => {
            dispatch(add(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setloading(false));
            dispatch(geterrors(err.response.data))
        });
};
export const editKycData = (data) => dispatch => {
    dispatch(setloading(true));

    dispatch(clearerror())

    axios.post(API_URL + "/api/kyc/edit", data)
        .then(res => {
            dispatch(edit(res.data));
        })
        .catch(err => {
            dispatch(setloading(false));
            dispatch(geterrors(err.response.data))
        });
};


export default counterSlice.reducer

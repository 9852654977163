
import axios from "axios";
// export const IMAGE_URL = 'http://localhost:5001/static/';
// export const API_URL = 'http://localhost:5001';
export const IMAGE_URL = 'https://validator-admin.frequencychain.org/static/';
export const API_URL = 'https://validator-admin.frequencychain.org';


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')



export const uploadFileServer = async (data) => {

  try {

    var response = await axios.post(`/upload`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getTxnStatus = async (txHash, provider) => {
  let transactionReceipt = null
  while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
    transactionReceipt = await provider.getTransactionReceipt(txHash);
    await sleep(3000)
  }
  if (transactionReceipt.status) {
    return [txHash, true];
  } else {
    return [txHash, false];
  }
}

export const addMilesSeparator = (n) => {
  n = Math.round(n * 100000) / 100000;
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const end = numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  return end;
}
import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './reducers/authReducer';
import { logoutUser } from './actions/authAction';

import axios from 'axios'
import { API_URL } from './actions/constant'


import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import PrivateRoute from './components/common/PrivateRoute';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// axios.defaults.baseURL = Constants.lifeCareApiUrl;
let persistor = persistStore(store);


axios.defaults.baseURL = API_URL;




//ADMIN ROUTE
const AdminLogin = React.lazy(() => import('./components/admin/auth/Login'));
const AdminVerifyOtp = React.lazy(() => import('./components/admin/auth/VerifyOtp'));
const Dashboard = React.lazy(() => import('./components/admin/dashboard/Dashboard'));
const ListUser = React.lazy(() => import('./components/admin/user/list/List'));
const ViewUser = React.lazy(() => import('./components/admin/user/view/View'));
const ListKyc = React.lazy(() => import('./components/admin/kyc/list/List'));
const ViewKyc = React.lazy(() => import('./components/admin/kyc/view/View'));

const AddSetting = React.lazy(() => import('./components/admin/setting/AddSetting'));


































if (localStorage.jwtToken) {
  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/login';
  }

}




export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          exact
          path="/"
        >
          <Suspense fallback={<div class="loaderdiv">
            <div class="loader" aria-hidden="true"></div>
          </div>}>
            {/* <div class="nk-app-root"> */}
            {/* ADMIN ROUTE */}
            <Routes>
              <Route index exact path="/" element={<AdminLogin />} />



              {/* //ADMIN ROUTE */}
              <Route index exact path="/admin/login" element={<AdminLogin />} />

              <Route index exact path="/admin/verifyotp" element={<AdminVerifyOtp />} />

              <Route path="/" element={<PrivateRoute />}>
                <Route index exact path="/admin/dashboard" element={<Dashboard />} />
                <Route index exact path="/admin/user/list" element={<ListUser />} />
                <Route index exact path="/admin/user/view/:id" element={<ViewUser />} />
                <Route index exact path="/admin/kyc/list" element={<ListKyc />} />
                <Route index exact path="/admin/kyc/view/:id" element={<ViewKyc />} />
                <Route index exact path="/admin/setting/" element={<AddSetting />} />
              </Route>
            </Routes>
            {/* </div> */}
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  )
}

import { createSlice } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
    name: 'web3',
    initialState: {
        provider: null,
        address: false,
        signer: null,
        web3Modal: null,
        connected: false,
        payablecontract: null,
        receivecontract: null,
        plugincontract: null,
        networkname: null,
        networkID: null,
        symbol: null,
        ethsalecontract: null,
        xdcsalecontract: null,
        polysalecontract: null,
        ethvestingcontract: null,
        xdcvestingcontract: null,
        polyvestingcontract: null,
        usdttokencontract: null,
        wbtctokencontract: null,
        ethvoytokencontract: null,
        xdcvoytokencontract: null,
        polyvoytokencontract: null,
        ethvoyairdropcontract: null,
        xdcvoyairdropcontract: null,
        polyvoyairdropcontract: null


    },
    reducers: {
        clearWeb3: async (state) => {
            // console.log("CLEAR CLLALLA")
            await state.web3Modal.clearCachedProvider();
            state.provider = null
            state.address = false
            state.signer = null
            state.web3Modal = null
            state.connected = false
            state.payablecontract = null
            state.receivecontract = null
            state.plugincontract = null
            state.networkname = null
            state.networkID = null
            state.symbol = null
            state.ethsalecontract = null
            state.xdcsalecontract = null
            state.polysalecontract = null
            state.ethvestingcontract = null
            state.xdcvestingcontract = null
            state.polyvestingcontract = null
            state.usdttokencontract = null
            state.wbtctokencontract = null
            state.ethvoytokencontract = null
            state.xdcvoytokencontract = null
            state.polysalecontract = null
            state.ethvoyairdropcontract = null
            state.xdcvoyairdropcontract = null
            state.polyvoyairdropcontract = null



            window.location.reload()
        },
        setWeb3: (state, action) => {
            state.provider = action.payload.provider
            state.networkname = action.payload.networkname
            state.networkID = action.payload.networkID
            state.symbol = action.payload.symbol
            state.balance = action.payload.balance
            state.address = action.payload.address
            state.signer = action.payload.signer
            state.web3Modal = action.payload.web3Modal
            state.payablecontract = action.payload.payablecontract
            state.receivecontract = action.payload.receivecontract
            state.plugincontract = action.payload.plugincontract
            state.connected = true
            state.ethsalecontract = action.payload.ethsalecontract
            state.xdcsalecontract = action.payload.xdcsalecontract
            state.polysalecontract = action.payload.polysalecontract
            state.ethvestingcontract = action.payload.ethvestingcontract
            state.xdcvestingcontract = action.payload.xdcvestingcontract
            state.polyvestingcontract = action.payload.polyvestingcontract
            state.usdttokencontract = action.payload.usdttokencontract
            state.wbtctokencontract = action.payload.wbtctokencontract
            state.ethvoytokencontract = action.payload.ethvoytokencontract
            state.xdcvoytokencontract = action.payload.xdcvoytokencontract
            state.polyvoytokencontract = action.payload.polyvoytokencontract
            state.ethvoyairdropcontract = action.payload.ethvoyairdropcontract
            state.xdcvoyairdropcontract = action.payload.xdcvoyairdropcontract
            state.polyvoyairdropcontract = action.payload.polyvoyairdropcontract

        },
        setNetwork: (state, action) => {
            state.networkID = action.payload.networkID
            state.address = action.payload.address
        },
        setBalance: (state, action) => {
            state.balance = action.payload.balance
        }
    },
})

export const { clearWeb3, setWeb3, setNetwork, setBalance } = counterSlice.actions



export default counterSlice.reducer

import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./reducers/authReducer"
import errorReducer from "./reducers/errorReducer"
import crudReducer from "./reducers/crudReducer"
import web3Reducer from "./reducers/web3Reducer"
import kycReducer from "./reducers/kycReducer"
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';


const reducers = combineReducers({
    auth: authReducer,
    errors: errorReducer,
    crud: crudReducer,
    web3: web3Reducer,
    kyc: kycReducer
});


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['web3']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
});



export default store;